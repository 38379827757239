import React from "react"
import PropTypes from "prop-types"

import FelaProvider from "../FelaProvider"
import { Heading1 } from "../Heading"

const LegalDocument = ({ title, children, styles, rules }) => (
  <FelaProvider>
    {title && <Heading1 customStyle={rules.title}>{title}</Heading1>}
    <main className={styles.page}>{children}</main>
  </FelaProvider>
)

LegalDocument.propTypes = {
  title: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  rules: PropTypes.shape({
    title: PropTypes.func.isRequired,
  }),
  styles: PropTypes.shape({
    page: PropTypes.string.isRequired,
  }),
}
LegalDocument.defaultProps = {
  title: null,
}

export default LegalDocument
