export const title = () => ({
  marginTop: "1rem",

  textTransform: "uppercase",
  fontSize: "2.5rem",
})

export const page = () => ({
  padding: "0 1.5rem",

  "& ol": {
    marginLeft: 0,

    listStyleType: "none",
    counterReset: "item",

    "& > li": {
      counterIncrement: "item",
    },
  },

  "& ul": {
    listStyleType: "circle",
  },
})
