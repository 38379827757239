import { connect } from "react-fela"
import LegalDocument from "./LegalDocument"
import * as styles from "./LegalDocument.styles"

import { LegalDocContent } from "./LegalDocContent"

const LegalDocumentConnected = connect(styles)(LegalDocument)

LegalDocumentConnected.Content = LegalDocContent

export default LegalDocumentConnected
