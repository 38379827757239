import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import PropTypes from "prop-types"

import { routes } from "../../../config"

import {
  default as Link,
  afaslovnikInfoMailLink,
  createAfaslovnikWebLink,
} from "../Link"

import { H2, H3, H4 } from "../Heading"

export const LegalDocContent = ({ name }) => {
  return (
    <FormattedMessage
      id={`legal.${name}.content`}
      values={{
        h2: chunks => <H2>{chunks}</H2>,
        h3: chunks => <H3>{chunks}</H3>,
        h3Cookies: chunks => <H3 id="cookies">{chunks}</H3>,
        h4: chunks => <H4>{chunks}</H4>,
        ol: chunks => <ol>{chunks}</ol>,
        ul: chunks => <ul>{chunks}</ul>,
        li: chunks => <li>{chunks}</li>,
        b: chunks => <b>{chunks}</b>,
        p: chunks => <p>{chunks}</p>,
        afaWebLink: createAfaslovnikWebLink(),
        afaslovnikInfoMailLink,
        afaWebPersonalDataProcessingLink: createAfaslovnikWebLink(
          routes.personalDataProcessing
        ),

        googlePrivacyLink: chunks => (
          <Link
            type="external"
            href="https://www.google.com/intl/cs/policies/privacy/partners/"
          >
            {chunks}
          </Link>
        ),
        googlePoliciesLink: chunks => (
          <Link
            type="external"
            href="https://policies.google.com/privacy?hl=cs"
          >
            {chunks}
          </Link>
        ),
        googleOptOutLink: chunks => (
          <Link
            type="external"
            href="https://tools.google.com/dlpage/gaoptout/"
          >
            {chunks}
          </Link>
        ),

        aboutCookiesLink: (
          <Link type="external" href="https://aboutcookies.org">
            https://www.aboutcookies.org
          </Link>
        ),
        geniePlatformLink: chunks => (
          <Link
            type="external"
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=cs"
          >
            {chunks}
          </Link>
        ),
        windowsPrivacyLink: chunks => (
          <Link
            type="external"
            href="https://support.microsoft.com/cs-cz/help/12454/windows-10-microsoft-edge-privacy-faq"
          >
            {chunks}
          </Link>
        ),
        explorerCookiesLink: chunks => (
          <Link
            type="external"
            href="https://support.microsoft.com/cs-cz/help/17442/windows-internet-explorer-delete-manage-cookies"
          >
            {chunks}
          </Link>
        ),
        mozillaCookiesLink: chunks => (
          <Link
            type="external"
            href="https://support.mozilla.org/cs/kb/blokovani-cookies"
          >
            {chunks}
          </Link>
        ),
        safariCookiesLink: chunks => (
          <Link
            type="external"
            href="https://support.apple.com/cs-cz/guide/safari/sfri11471/mac"
          >
            {chunks}
          </Link>
        ),
        uoouLink: (
          <Link type="external" href="https://www.uoou.cz">
            www.uoou.cz.
          </Link>
        ),
      }}
    />
  )
}

LegalDocContent.propTypes = {
  name: PropTypes.string.isRequired,
}
