import React from "react"
import { FelaComponent } from "react-fela"

import * as styles from "./Heading.styles"

const createHeading = type => {
  const style = styles[type]

  if (!style) {
    console.warn(`Type ${type} is not supported for legal doc heading`)
  }

  return ({ id, children, ...props }) => (
    <FelaComponent style={style} {...props}>
      {({ className }) =>
        React.createElement(type, { className, id, name: id }, children)
      }
    </FelaComponent>
  )
}

export default createHeading
