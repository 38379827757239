import { combineRules } from "fela"

const baseHeadingStyle = () => ({
  display: "initial",

  "&:before": {
    content: 'counters(item, ".") ". "',
  },
})

export const h2 = combineRules(baseHeadingStyle, () => ({
  textTransform: "uppercase",
}))

export const h3 = combineRules(baseHeadingStyle, () => ({}))

export const h4 = combineRules(baseHeadingStyle, () => ({}))
