import React from "react"
import Link from "./Link"

export const createAfaslovnikWebLink = (page = "/") => (
  <Link to={page}>www.afaslovnik.cz{page === "/" ? "" : page}</Link>
)

export const afaslovnikInfoMailLink = (
  <Link type="external" href="mailto:info@afaslovnik.cz">
    info@afaslovnik.cz
  </Link>
)
