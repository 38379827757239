import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"

import LegalDocument from "../LegalDocument"

const DataProcessingDoc = () => (
  <LegalDocument title={<FormattedMessage id="legal.data.title" />}>
    <LegalDocument.Content name="data" />
  </LegalDocument>
)

DataProcessingDoc.propTypes = {}

export default DataProcessingDoc
